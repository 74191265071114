@font-face {
  font-family: "apersu";
  src: local("apersuFont.woff"), url(./fonts/apersuFont.woff) format("woff");
}
@font-face {
  font-family: "apersuBold";
  src: local("apersuFontBold.woff"),
    url(./fonts/apersuFontBold.woff) format("woff");
}
@font-face {
  font-family: "apersuLogo";
  src: local("apersuFontLogo.woff"),
    url(./fonts/apersuFontLogo.woff) format("woff");
}
h1 {
  font-size: calc(10px + 1.7vw);
}
h2 {
  font-size: calc(8px + 1.7vw);
}
.App {
  text-align: center;
  font-family: "apersu";
}
b {
  font-family: "apersuBold";
}
.App .ae-logo {
  font-size: calc(12px + 2vw);
  font-family: "apersuLogo";
}
.App .footerlogo {
  font-size: calc(11px + 0.2vw);
  font-family: "apersuLogo";
}
.App .apersu-logo {
  font-size: calc(11px + 2vw);
}
.App .apersu-logolg {
  font-size: calc(11px + 1vw);
}
.App .apersu-logomd {
  font-size: calc(12px + 0.1vw);
}
.App .passwordRules {
  font-size: calc(12px + 0.3vw);
}
.App .pageTitle {
  font-size: calc(15px + 0.9vw);
}
.App .pageTitleSm {
  font-size: calc(15px + 1.5vw);
}
.App .pageSubheading {
  font-size: calc(15px + 2vw);
}
.App .pageSubheadingSm {
  font-size: calc(15px + 3vw);
}
.App .tableHeader {
  font-size: calc(8px + 0.5vw);
}
.App .tableBody {
  font-size: calc(12px + 0.5vw);
}
.App .companyTitle {
  font-size: calc(15px + 1.5vw);
}
.App .cardText {
  font-size: calc(12px + 1.5vw);
}
.App .cardTitle {
  font-size: calc(15px + 1.9vw);
}
.App .lgcardText {
  font-size: calc(12px + 1.1vw);
}
.App .lgcardTitle {
  font-size: calc(15px + 1.2vw);
}
.App .smcardText {
  font-size: calc(12px + 1.9vw);
}
.App .table {
  font-size: calc(8px + 0.6vw);
}
.App .tab {
  font-size: calc(7px + 0.8vw);
}
.App .tabTitle {
  font-size: calc(0.7em + 0.1vw);
}
.App .materialCost {
  font-size: calc(8px + 1.2vw);
}
.App .materialCostsm {
  font-size: calc(10px + 2.1vw);
}
.App .pageSubtitle {
  font-size: calc(8px + 1vw);
}
.App .estimateDetailTitle {
  font-size: calc(10px + 1.1vw);
}
.App .estimateDetailTitleSm {
  font-size: calc(13px + 1.3vw);
}
.App .estimateDetailBody {
  font-size: calc(7px + 1.1vw);
}
.App .estimateDetailBodySm {
  font-size: calc(12px + 1.1vw);
}
.App .breadcrumbs {
  font-size: calc(12px + 0.5vw);
}
.App .noEstimatesErrorText {
  color: red;
}
.App .blueLink {
  color: rgb(2, 32, 121);
}
.App .cardImageDiv {
  max-height: 400px;
}
.App .cardImage {
  width: 100%;
  height: 100%;
}
.App .cardLogoDiv {
  max-height: 300px;
}
.App .formInputError {
  color: crimson;
}
.App .estimateSummarySubtitle {
  font-size: calc(8px + 1vw);
}
.App .estimateSummaryContent {
  font-size: calc(7px + 0.9vw);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}


/* Countdown Spinner */

/* Define the progress animation */
@keyframes progress {
  /* Start with the circle complete (0 offset) */
  0% { stroke-dashoffset: 0; }
  /* Move to 1/3 complete (1/3 of 283 is about 94.33) */
  31% { stroke-dashoffset: 94.33; }
  /* Move to 2/3 complete (2/3 of 283 is about 188.67) */
  64% { stroke-dashoffset: 188.67; }
  /* End with the circle empty (full offset of 283) */
  100% { stroke-dashoffset: 283; }
}

/* Define the countdown number animation */
@keyframes count-down {
  /* Show "3" for the first third of the animation */
  0%, 33.33% { content: ""; }
  /* Show "2" for the second third of the animation */
  33.34%, 66.66% { content: ""; }
  /* Show "1" for the final third of the animation */
  66.67%, 100% { content: ""; }
}

/* Apply the progress animation to the SVG circle */
.animate-progress {
  /* Use the 'progress' animation, lasting 3 seconds, with a custom timing function, repeating infinitely */
  animation: progress 3s cubic-bezier(0.5, 0, 0.5, 0.7) infinite;
}

/* Style for the countdown number */
.countdown-number::after {
  /* Set the initial content to "3" */
  content: "3";
  /* Apply the count-down animation, lasting 3 seconds, changing discretely at the end of each step, repeating infinitely */
  animation: count-down 3s steps(1, end) infinite;
}

/* Explanation of the cubic-bezier(0.25, 0, 0.75, 1) timing function:
 * - It starts slightly faster than linear (0.25, 0)
 * - Maintains speed through the middle
 * - Slows down slightly towards the end (0.75, 1)
 * This creates a smooth animation that slows down as it approaches each number change
 */
/* Spinner END */

/* Cooldown Spinner for Verify Email */

.cooldown-spinner {
  position: relative;   /* Allows absolute positioning of child elements */
  width: 50px;          /* Sets the width of the spinner container */
  height: 50px;         /* Sets the height of the spinner container */
  margin: 0 auto;       /* Centers the spinner horizontally */
}

/* Styles for the SVG spinner element */
.spinner {
  z-index: 2;           /* Ensures the spinner appears above other elements */
  position: absolute;   /* Positions the spinner relative to its container */
  top: 50%;             /* Moves the spinner down by 50% of its container's height */
  left: 50%;            /* Moves the spinner right by 50% of its container's width */
  margin: -25px 0 0 -25px; /* Offsets the spinner to truly center it (half of width/height) */
  width: 50px;          /* Sets the width of the SVG */
  height: 50px;         /* Sets the height of the SVG */
}

/* Styles for the background circle of the spinner */
.spinner .path {
  stroke: hsl(210, 70%, 75%);  /* Sets the color of the background circle (light blue) */
  stroke-linecap: round;       /* Makes the ends of the stroke rounded */
}

/* Styles for the progress circle of the spinner */
.spinner .progress {
  stroke: hsl(210, 70%, 45%);  /* Sets the color of the progress circle (dark blue) */
  stroke-linecap: round;       /* Makes the ends of the stroke rounded */
  transition: stroke-dasharray 0.1s linear;  /* Smooths out the animation of the depleting effect */
}

/* Styles for the countdown number in the center of the spinner */
.countdown {
  position: absolute;   /* Positions the countdown relative to the spinner container */
  top: 50%;             /* Moves the countdown down by 50% of the container's height */
  left: 50%;            /* Moves the countdown right by 50% of the container's width */
  transform: translate(-50%, -50%);  /* Truly centers the countdown text */
  font-size: 18px;      /* Sets the font size of the countdown number */
}

/* You can add additional styles here to customize the appearance further */
/* For example: */
.cooldown-spinner {
  margin-top: 10px;     /* Adds some space above the spinner */
  margin-bottom: 10px;  /* Adds some space below the spinner */
}

.countdown {
  color: #333;          /* Changes the color of the countdown number */
  font-family: Arial, sans-serif;  /* Changes the font of the countdown number */
}

/* Cooldown Spinner END */

/* Pasword Validation Icon Fade */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-icon {
  animation: fadeIn 0.15s ease-in-out;
}
/* Pasword Validation Icon Fade END */

/* Verify Email Div Animations */
.verify-email-card {
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  overflow: hidden;
}

.verify-email-card.expanded {
  max-height: 600px; /* Adjust this value based on your content */
  padding-bottom: 1rem;
}

.verify-email-card.collapsed {
  max-height: 400px; /* Adjust this value to match the height without the timer */
  padding-bottom: 1rem;
}

.cooldown-spinner-container {
  transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
}

.cooldown-spinner-container.visible {
  opacity: 1;
  max-height: 100px; /* This should be enough to show the spinner */
}

/* Add this new rule to make the changes more visible */
.card-body {
  transition: padding 0.5s ease-in-out;
}

.verify-email-card.expanded .card-body {
  padding-bottom: 0rem;
}

.verify-email-card.collapsed .card-body {
  padding-bottom: 0rem;
}

/* Ensure smooth opacity transition for the timer */
.cooldown-spinner-container {
  transition: opacity 1.5s ease-in-out, max-height 0.5s ease-in-out;
}
/* Verify Email Div Animations END */


/* MFA setup Animations */
.mfa-manager {
  position: relative;
  min-height: 200px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Common styles for all steps (enrollment, verification, re-authentication) */
.mfa-step {
  width: 100%;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

/* Active step styles */
.mfa-step.active {
  opacity: 1;
  visibility: visible;
}

/* Error message styles */
.error-message {
  color: #d9534f;
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Success message styles */
.success-message {
  color: #5cb85c;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.9rem;
}

/* Ensure the input and button have consistent widths */
.mfa-step .form-control,
.mfa-step .btn {
  width: 100%;
  max-width: 300px;
}

/* Add some space between elements */
.mfa-step > * {
  margin-bottom: 1rem;
}

/* Remove margin from the last child to avoid extra space at the bottom */
.mfa-step > *:last-child {
  margin-bottom: 0;
}
/* MFA setup Animations END */

/* Verification code container */
/* Update the existing verification code container styles */
.verification-code-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  max-width: 300px; /* Adjust as needed */
  margin-left: auto;
  margin-right: auto;
}

/* Update the existing verification code input styles */
.verification-code-container input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 2px;
}

/* Add responsive styles */
@media (max-width: 480px) {
  .verification-code-container input {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}

/* Focus style for verification code input */
.verification-code-container input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}
/* Verification code container END */
.modal {
  transition: opacity 300ms ease-in-out;
}

/* Google Places Autocomplete Styling */
/* Google Places Autocomplete Styling */

/* Light Mode Styles */
.pac-container {
  background-color: #ededed;
  border: 2px solid #881337;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
}

.pac-item {
  background-color: #ededed;
  color: #111827;
}

.pac-item:hover {
  background-color: #d1d5db;
}

.pac-item-query {
  color: #881337;
}

.pac-matched {
  color: #881337;
}

/* Dark Mode Styles */
[data-theme='apersudark'] .pac-container {
  background-color: #1d232a;
  border: 2px solid #881337;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}

[data-theme='apersudark'] .pac-item {
  background-color: #1d232a;
  color: #f3f4f6;
}

[data-theme='apersudark'] .pac-item:hover {
  background-color: #2e3842;
}

[data-theme='apersudark'] .pac-item-query {
  color: #f97316;
}

[data-theme='apersudark'] .pac-matched {
  color: #f97316;
}

/* Common Styles */
.pac-container {
  font-family: "apersu", Arial, sans-serif;
  font-size: 14px;
  padding: 5px;
  z-index: 9999 !important;
  opacity: 1 !important;
}

.pac-item {
  padding: 5px;
}

.pac-matched {
  font-weight: bold;
}

/* Ensure the dropdown is visible when active */
.pac-container:not([style*="display: none"]) {
  display: block !important;
}

/* Rounded corners for the first and last items */
.pac-item:first-child {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.pac-item:last-child {
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
}

/* Add this new rule to hide the footer */
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

/* Safari-specific fix to hide autocomplete dropdown after selection */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .pac-container {
      display: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
      pointer-events: none !important;
    }
  }
}

/* Ensure the dropdown is visible when active */
.pac-container:not([style*="display: none"]) {
  display: block !important;
}
/* End of Google Places Autocomplete Styling */

* {
  box-sizing: border-box;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safari-specific-class {
      /* Add your Safari-specific CSS here */
      display: flex;
      flex-direction: column;
    }
  }
}
* {
  box-sizing: border-box;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* .App .container {
  flex-grow: 1;
  padding: 0 60px;
} */
