input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input .percent{
  text-align: right;
  }

  .badge-placeholder {
    width: 0.5rem;
    height: 0.5rem;
    display: inline-block;
  }
  .input-container {
    position: relative;
  }
  .badge-container {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }